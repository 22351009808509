import ThemeCard from "../../../components/ThemeCard";
import { useSelector } from "react-redux";
import React, { Suspense, lazy } from "react";
import CustomerView from "./components/CustomerView";
import RoleWiseView from "./components/RoleWiseView";
import { Skeleton } from "antd";
const LazyCustomerView = lazy(() => import("./components/CustomerView"));
const LazyRoleWiseView = lazy(() => import("./components/RoleWiseView"));

const AccessDetailsView = React.memo(({ is_edits, parentForm }) => {
  //redux
  const accountType = useSelector(
    (state) => state.userAccountSlice?.accountType
  );

  return (
    <ThemeCard
      title="Account details"
      bordered={false}
      style={{ marginBlock: "3rem", flexDirection: "column", width: "100%" }}
    >
      {accountType === "customer" ? (
        <Suspense fallback={<Skeleton />}>
          <LazyCustomerView is_edits={is_edits} parentForm={parentForm} />
        </Suspense>
      ) : (
        <Suspense fallback={<Skeleton />}>
          <LazyRoleWiseView is_edits={is_edits} parentForm={parentForm} />
        </Suspense>
      )}
    </ThemeCard>
  );
});

export default AccessDetailsView;
