import React, { useEffect, useRef, useState } from "react";
import ThemeCard from "../../components/ThemeCard";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Pagination,
  Row,
  Skeleton,
  Tabs,
} from "antd";
import { useSelector } from "react-redux";
import {
  BlockScriptTitle,
  CheckBoxColumn,
  StyleTitle,
  StyledCollapse,
  StyledRow,
} from "./style";
import EditBlockScript from "./EditBlockScript";
import {
  useBlockScriptList,
  useEditUserDetails,
  useUpdateCSV,
} from "../../APICall/apiHooks";

function TradingBlocked() {
  const [unBlock, setUnBlock] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPage: 1,
    totalCount: 1,
    data: [],
  });
  const scriptRef = useRef({});
  const [form] = Form.useForm();
  const [marketWiseForm] = Form.useForm();

  // custom hook for update csv
  const { updateCSVData, updateCSVLoading, updateCSVMutate } = useUpdateCSV();

  // redux
  const loginUserId = useSelector(
    (state) => state.authSlice?.userDetail?.userId
  );
  const loginAccountType = useSelector(
    (state) => state.authSlice?.userDetail?.accountType
  );
  const isOwner = loginAccountType === "owner";
  const canShowBlock =
    loginAccountType !== "customer" && loginAccountType !== "broker";
  const PageSize = canShowBlock ? 10 : 20;
  const marketList = useSelector((state) => state?.globleSlice?.marketListData);
  // const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);

  // custom hook for get blocked scripts
  const { allBlockScriptData, allBlockScriptLoading } =
    useBlockScriptList(loginUserId);

  // custom hook for edit user, use for edit block script
  const { editUserLoading, editUserData, editUserMutate } =
    useEditUserDetails(loginUserId);

  // life cycle
  useEffect(() => {
    if (allBlockScriptData) {
      const total = allBlockScriptData?.length;
      setPagination({
        page: 1,
        totalPage: Math.ceil(total / PageSize),
        totalCount: total,
        data: allBlockScriptData.slice(0, PageSize),
      });
      const initialFormValues = {};
      allBlockScriptData.forEach((item) => {
        initialFormValues[
          `${item.Script}$${item.marketName}$${item.blockedBy}`
        ] = false;
      });
      form.setFieldsValue(initialFormValues);
      setUnBlock(false);
    }
  }, [allBlockScriptData]);

  // function
  const tabList = marketList?.map((el, index) => {
    return {
      key: index + 1,
      label: el?.label,
      children: (
        <EditBlockScript
          form={marketWiseForm}
          tab={el?.scriptName}
          scriptRef={scriptRef}
          preBlocked={allBlockScriptData?.filter(
            (item) => item?.blockedBy !== "Blocked BY NSE"
          )}
        />
      ),
    };
  });

  const items = [
    {
      key: "1",
      label: <StyleTitle level={5}>Edit Block Script</StyleTitle>,
      children: <Tabs defaultActiveKey="1" items={tabList} />,
    },
  ];

  const middleIndex = Math.ceil(pagination.data?.length / 2);
  const leftItems = pagination.data?.slice(0, middleIndex);
  const rightItems = pagination.data?.slice(middleIndex);

  const handleUnBlock = (value) => {
    const allValue = form.getFieldValue();
    let emptymarket = [];
    const marketWiseBlockScript = Object.entries(allValue)?.reduce(
      (acc, [key, isBlock]) => {
        let [symbolName, marketName, blockedBy] = key.split("$");

        if (blockedBy === "Blocked BY NSE") return acc;
        const isnotNSE = allBlockScriptData?.find(
          (el) =>
            el?.Script === symbolName && el?.blockedBy !== "Blocked BY NSE"
        );
        if (isBlock && isnotNSE) {
          const index = emptymarket?.findIndex((el) => el === marketName);
          if (index === -1) {
            emptymarket.push(marketName);
          }
          return acc;
        }

        if (isnotNSE) {
          const findScript = acc?.findIndex(
            (el) => el.scriptName === marketName
          );
          if (findScript === -1) {
            let obj = {
              scriptName: marketName,
              blockScript: [symbolName],
            };
            acc.push(obj);
          } else {
            acc[findScript].blockScript.push(symbolName);
          }
        }

        return acc;
      },
      []
    );

    emptymarket?.forEach((el) => {
      let isPresentEmpty = marketWiseBlockScript?.find(
        (item) => item?.scriptName === el
      );

      if (!isPresentEmpty) {
        marketWiseBlockScript.push({
          scriptName: el,
          blockScript: [],
        });
      }
    });

    const filteredMarket = marketWiseBlockScript?.filter((el) => {
      const isValid = emptymarket?.includes(el?.scriptName);
      if (isValid) {
        return el;
      }
    });

    const payload = {
      accountType: loginAccountType,
      marketAccess: filteredMarket,
    };
    form.resetFields();
    editUserMutate(payload);
  };

  const handleFomChange = (e) => {
    const formValue = form.getFieldValue();

    const hasAnyTruthyValue = Object.values(formValue).some(Boolean);
    setUnBlock(hasAnyTruthyValue);
  };
  const handleUpdateCSV = () => {
    updateCSVMutate();
  };

  const handlePageChange = (value) => {
    const start = (value - 1) * PageSize;
    setPagination((pre) => ({
      ...pre,
      page: value,
      data: allBlockScriptData.slice(start, start + PageSize),
    }));
  };

  return (
    <>
      <ThemeCard
        title={
          <BlockScriptTitle isOwner={isOwner}>
            <h3>Blocked Script List</h3>
            {isOwner && (
              <Button
                type="primary"
                onClick={handleUpdateCSV}
                loading={updateCSVLoading}
              >
                Update CSV
              </Button>
            )}
          </BlockScriptTitle>
        }
      >
        <Form form={form} onFinish={handleUnBlock} onChange={handleFomChange}>
          <Row gutter={[20, 5]}>
            <Col xs={24} md={12}>
              <StyledRow align={"middle"} justify={"space-between"}>
                <Col span={4}>SR No.</Col>
                <Col span={10}>Script Name</Col>
                <Col span={10} style={{ paddingLeft: "1.5rem" }}>
                  Blocked By
                </Col>
              </StyledRow>
              {allBlockScriptLoading ? (
                <Skeleton active />
              ) : (
                leftItems?.map((el, index) => (
                  <Row key={index} gutter={[10, 5]} justify={"space-between"}>
                    <Col span={4} style={{ paddingLeft: "1.5rem" }}>
                      {index + 1}
                    </Col>
                    <CheckBoxColumn span={10}>
                      <Form.Item
                        name={`${el?.Script}$${el?.marketName}$${el?.blockedBy}`}
                        // initialValue={true}
                        valuePropName="checked"
                      >
                        <Checkbox
                          name={`${el?.Script}$${el?.marketName}$${el?.blockedBy}`}
                          disabled={!(loginUserId === el?.userId)}
                        ></Checkbox>
                      </Form.Item>
                      <p>{el?.Script}</p>
                    </CheckBoxColumn>

                    <Col span={10}>
                      <p>{`${el?.blockedBy} ${
                        el?.userId ? `(${el?.userId})` : ""
                      }${el?.date ? `(${el?.date})` : ""}`}</p>
                    </Col>
                  </Row>
                ))
              )}
            </Col>

            <Col xs={24} md={12}>
              <StyledRow
                align={"middle"}
                display="block"
                justify={"space-between"}
              >
                <Col span={4}>SR No.</Col>
                <Col span={10}>Script Name</Col>
                <Col span={10} style={{ paddingLeft: "1.5rem" }}>
                  Blocked By
                </Col>
              </StyledRow>
              {allBlockScriptLoading ? (
                <Skeleton active />
              ) : (
                rightItems?.map((el, index) => (
                  <Row key={index} gutter={10} justify={"space-between"}>
                    <Col span={4} style={{ paddingLeft: "1.5rem" }}>
                      {index + middleIndex + 1}
                    </Col>

                    <CheckBoxColumn span={10}>
                      <Form.Item
                        name={`${el?.Script}$${el?.marketName}$${el?.blockedBy}`}
                        // initialValue={true}
                        valuePropName="checked"
                      >
                        <Checkbox
                          name={`${el?.Script}$${el?.marketName}$${el?.blockedBy}`}
                          disabled={!(loginUserId === el?.userId)}
                        ></Checkbox>
                      </Form.Item>
                      <p>{el?.Script}</p>
                    </CheckBoxColumn>

                    <Col span={10}>
                      <p>{`${el?.blockedBy} ${
                        el?.userId ? `(${el?.userId})` : ""
                      }${el?.date ? `(${el?.date})` : ""}`}</p>
                    </Col>
                  </Row>
                ))
              )}
            </Col>
          </Row>
          <Row justify={"end"} style={{ marginTop: "1rem" }}>
            <Pagination
              current={pagination.page}
              total={pagination.totalCount}
              pageSize={PageSize}
              onChange={handlePageChange}
              showSizeChanger={false}
            />
            {canShowBlock && (
              <Button
                type="primary"
                htmlType="submit"
                disabled={!unBlock}
                loading={editUserLoading}
              >
                Un-Block
              </Button>
            )}
          </Row>
        </Form>
      </ThemeCard>
      {canShowBlock && <StyledCollapse items={items} />}
    </>
  );
}

export default TradingBlocked;
