import { WhatsAppOutlined } from "@ant-design/icons";
import { Descriptions, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WhatsappShareButton } from "react-share";
import { setApiLink } from "../../../../store/slice/globleSlice";
import { getApplicationLink } from "../../../../components/applicationLink";
import { CopyButton } from "../style";

const CreateUserModal = ({
  newUserDetails,
  isModalOpen,
  setIsModalOpen,
  type,
}) => {
  // state
  const [copy, setCopy] = useState(false);
  const [text, setText] = useState("");
  const [column, setColumn] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const title = "Welcome to TradeSpace,\n\n";

  //redux
  const apiLink = useSelector((state) => state?.globleSlice?.apiLink);
  const marketAccess = newUserDetails?.payload?.[0]?.marketAccess?.map(
    (el) => el.scriptName
  );

  const handleCopy = async () => {
    try {
      await navigator.clipboard?.writeText(`${title}${text}`);
      setCopy(true);
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  // data
  const items = [
    {
      key: "1",
      label: "User ID",
      children: `${newUserDetails?.userId}`,
    },
    {
      key: "2",
      label: "Username",
      children: `${newUserDetails?.accountName}`,
    },
    {
      key: "3",
      label: "User Passward",
      children: `${newUserDetails?.passward}`,
    },
    {
      key: "4",
      label: "Account Type",
      children: `${newUserDetails?.accountType}`,
    },
    {
      key: "5",
      label: "Website Link",
      children: (
        <a
          href="https://tradespace.site/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          tradespace.site
        </a>
      ),
    },
    {
      key: "6",
      label: "Android Link",
      children: (
        <a href={apiLink?.android} target="_blank" rel="noopener noreferrer">
          tradespace.site
        </a>
      ),
    },
    {
      key: "7",
      label: "Apple Link",
      children: (
        <a href={apiLink?.apple} target="_blank" rel="noopener noreferrer">
          tradespace.site
        </a>
      ),
    },
    {
      key: "8",
      label: "Share with What's up",
      children: (
        <WhatsappShareButton url={text} title={title}>
          <WhatsAppOutlined style={{ fontSize: "1.7rem" }} />
        </WhatsappShareButton>
      ),
    },
    {
      key: "9",
      label: "Copy Details",
      children: (
        <CopyButton onClick={handleCopy}>
          {copy ? "Copied!" : "Copy"}
        </CopyButton>
      ),
    },
  ];

  // life cycle
  useEffect(() => {
    getApplicationLink().then((data) => {
      dispatch(setApiLink(data));
    });
  }, []);

  useEffect(() => {
    if (marketAccess?.length <= 0) {
      return;
    }
    let textMarket = "";
    marketAccess?.forEach((el) => {
      textMarket += `✅ ${el},\n`;
    });
    setText(() => {
      if (newUserDetails?.accountType === "customer") {
        return `UserName: ${newUserDetails.accountName},\n\nUserId: ${newUserDetails.userId},\n\nPassward: ${newUserDetails.passward},\n\nAccountType: ${newUserDetails.accountType},\n\n${textMarket}\n\nAndroid Link: ${apiLink?.android},\n\nApple Link: ${apiLink?.apple}\n\nPCLINK: https://tradespace.site/login,\n\nT&C\n• THIS WEBSITE IS ONLY FOR EDUCATION PURPOSE.\n• ANY LOSS/PROFIT SHOWN ON THUS WEBSITE SHALL NOT INVOLVEMENT OF ANY MONETARY VALUE.`;
      } else {
        return `UserName: ${newUserDetails.accountName},\n\nUserId: ${newUserDetails.userId},\n\nPassward: ${newUserDetails.passward},\n\nAccountType: ${newUserDetails.accountType},\n\n${textMarket}\n\nPCLINK: https://tradespace.site/login,\n\nT&C\n• THIS WEBSITE IS ONLY FOR EDUCATION PURPOSE.\n• ANY LOSS/PROFIT SHOWN ON THUS WEBSITE SHALL NOT INVOLVEMENT OF ANY MONETARY VALUE.`;
      }
    });
    setColumn(() => {
      if (newUserDetails?.accountType !== "customer") {
        return items?.filter((el) => el?.key !== "6" && el?.key !== "7");
      } else {
        return items;
      }
    });
  }, [apiLink, newUserDetails, copy]);

  // function
  const handleOk = () => {
    setIsModalOpen(false);
    navigate(`/users/accounts?type=${type === "rent" ? "rent" : "default"}`);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    navigate(`/users/accounts?type=${type === "rent" ? "rent" : "default"}`);
  };

  return (
    <Modal
      title="Created User Info"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
    >
      <Descriptions items={column} style={{ marginTop: "2rem" }} />
    </Modal>
  );
};

export default React.memo(CreateUserModal);
